import { FC, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Progress from "./Progress";
import { WrappedAuthProvider } from "../Contexts/AuthProvider";
import ScrollToTop from "./ScrollToTop";
import { GuardedRoute } from "./GuardedRoute";
import SetupMFA from "../Components/MFA/SetupMFA";
import ValidateMFA from "../Components/MFA/ValidateMFA";
import ResetPassword from "../Components/ResetPassword/ResetPassword";
import UpdatePassword from "../Components/ResetPassword/UpdatePassword";
import Extension from "../Components/Extension/Extension";
import { lazyWithRetry } from "./LazyWithRetry";
import App from "../App";

const Landing = lazyWithRetry(() => import("../Components/Landing/Landing"));
const UsageGuide = lazyWithRetry(() => import("../Components/Landing/UsageGuide"));
const AboutUs = lazyWithRetry(() => import("../Components/Landing/AboutUs"));
const LoginPage = lazyWithRetry(() => import("../Components/Login/LoginPage"));
const Logout = lazyWithRetry(() => import("../Components/Login/Logout"));
const Terms = lazyWithRetry(() => import("./Terms"));
const Feedback = lazyWithRetry(() => import("../Components/Feedback/Feedback"));
const FeedbackSent = lazyWithRetry(() => import("../Components/Feedback/FeedbackSent"));
const GetSupport = lazyWithRetry(() => import("../Components/Feedback/GetSupport"));
const SupportRequestSent = lazyWithRetry(() => import("../Components/Feedback/SupportRequestSent"));
const ContactSales = lazyWithRetry(() => import("../Components/Feedback/ContactSales"));
const ContactSalesSent = lazyWithRetry(() => import("../Components/Feedback/ContactSalesSent"));
const PrivacyPolicy = lazyWithRetry(() => import("./PrivacyPolicy"));
const Dashboard = lazyWithRetry(() => import("../Components/Dashboard/Dashboard"));
const MyAccount = lazyWithRetry(() => import("../Components/Account/MyAccount"));
const Contacts = lazyWithRetry(() => import("../Components/Dashboard/Contacts"));
const ViewEmovid = lazyWithRetry(() => import("../Components/ViewEmovid/ViewEmovid"));
const RecordNow = lazyWithRetry(() => import("../Components/CreateEmovid/RecordNow"));
const RedirectToRecordNow = lazyWithRetry(() => import("../Components/CreateEmovid/RedirectToRecordNow"));
const EmovidSent = lazyWithRetry(() => import("../Components/CreateEmovid/EmovidSent"));
const EmovidDeleted = lazyWithRetry(() => import("../Components/ViewEmovid/EmovidDeleted"));
const CreateEmovid = lazyWithRetry(() => import("../Components/CreateEmovid/CreateEmovid"));
const RecordResponse = lazyWithRetry(() => import("../Components/Respond/RecordResponse"));
const ResponsePreview = lazyWithRetry(() => import("../Components/Respond/ResponsePreview"));
const ResponseSent = lazyWithRetry(() => import("../Components/Respond/ResponseSent"));
const HowToWhitelist = lazyWithRetry(() => import("../Components/HowToWhitelist/HowToWhitelist"));
const UseCases = lazyWithRetry(() => import("../Components/UseCases/UseCases"));
const ViewVideo = lazyWithRetry(() => import("../Components/ViewVideo"));
const FAQPage = lazyWithRetry(() => import("../Components/FAQs/FAQPage"));

export const Paths = {
    landing: "/",
    login: "/login",
    logout: "/logout",
    dashboard: "/dashboard",
    myInbox: "/dashboard/inbox",
    createdVideos: "/dashboard/created-videos",
    resources: "/dashboard/resources",
    persona: "/dashboard/persona",
    myAccount: "/account",
    usageGuide: "/usage-guide",
    usageGuideResources: "/dashboard/resources?selected_filter=usage_guide",
    sendFeedback: "/feedback",
    recordFeedback: "/feedback/:postId/record",
    editFeedbackVideo: "/feedback/:postId/edit-video",
    feedbackSent: "/feedback/success",
    getSupport: "/support",
    aboutUs: "/about-us",
    recordSupport: "/support/:postId/record",
    editSupportVideo: "/support/:postId/edit-video",
    supportRequestSent: "/support/success",
    contactSales: "/contact-sales",
    recordContactSales: "/contact-sales/:postId/record",
    editContactSalesVideo: "/contact-sales/:postId/edit-video",
    contactSalesRequestSent: "/contact-sales/success",
    contacts: "/contacts",
    processing: "/processing",
    emovid: "/emovid/:eventId",
    recordEmovid: "/emovid/:eventId/record",
    editEmovidVideo: "/emovid/:eventId/edit-video",
    preview: "/emovid/:eventId/preview",
    previewAndEdit: "/emovid/:eventId/view?preview=true",
    sent: "/emovid/:eventId/sent",
    extension: "/extension",
    viewEmovid: "/emovid/:eventId/view",
    emovidDeleted: "/emovid/deleted",
    viewVideo: "/video/:videoKey",
    respond: "/respond/:eventId",
    recordResponse: "/respond/:postId/record",
    editResponseVideo: "/respond/:postId/edit-video",
    previewResponse: "/respond/:postId/preview",
    responseSent: "/respond/success",
    setUpMFA: "/mfa/setup",
    validateMFA: "/mfa/validate",
    resetPassword: "/reset-password",
    updatePassword: "/update-password",
    howToWhitelist: "/how-to-whitelist",
    useCases: "/use-cases",
    useCasesResources: "/dashboard/resources?selected_filter=use_cases",
    faqPage: "/faqs",
    faqResources: "/dashboard/resources?selected_filter=faqs",
    terms: "/terms",
    privacyPolicy: "/privacy"
};

export const DefaultLoggedinUserPath = Paths.myInbox;
export const RoutesWithFooterEnabled = [Paths.landing, Paths.terms, Paths.privacyPolicy, Paths.useCases, Paths.aboutUs];

export const GuardedRoutes = [
    { path: Paths.setUpMFA, component: <SetupMFA /> },
    { path: Paths.dashboard, component: <Dashboard /> },
    { path: Paths.myInbox, component: <Dashboard /> },
    { path: Paths.createdVideos, component: <Dashboard /> },
    { path: Paths.resources, component: <Dashboard /> },
    { path: Paths.usageGuideResources, component: <Dashboard /> },
    { path: Paths.useCasesResources, component: <Dashboard /> },
    { path: Paths.faqResources, component: <Dashboard /> },
    { path: Paths.persona, component: <Dashboard /> },
    { path: Paths.sendFeedback, component: <Feedback /> },
    { path: Paths.feedbackSent, component: <FeedbackSent /> },
    { path: Paths.contacts, component: <Contacts /> },
    { path: Paths.myAccount, component: <MyAccount /> },
    { path: Paths.validateMFA, component: <ValidateMFA /> }
];

export const replaceEventIdInPath = (path: string, eventId: string): string => {
    return path.replace(":eventId", `${eventId}`);
};

export const replacePostIdInPath = (path: string, postId: string): string => {
    return path.replace(":postId", `${postId}`);
};

export const replaceVideoKeyInPath = (path: string, videoKey: string): string => {
    return path.replace(":videoKey", `${videoKey}`);
};

export const replaceMemberIdInPath = (path: string, eventId: string): string => {
    return path.replace(":memberId", `${eventId}`);
};

const AppRoutes: FC = () => (
    <Suspense fallback={<Progress />}>
        <BrowserRouter>
            <ScrollToTop />
            <WrappedAuthProvider>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route path={Paths.login} element={<LoginPage />} />
                        <Route path={Paths.logout} element={<Logout />} />
                        {GuardedRoutes.map((guardedRoute: any, i: any) => (
                            <Route key={i} path={guardedRoute.path} element={<GuardedRoute>{guardedRoute.component}</GuardedRoute>} />
                        ))}
                        <Route path={Paths.emovid} element={<RedirectToRecordNow />} />
                        <Route path={Paths.usageGuide} element={<UsageGuide />} />
                        <Route path={Paths.emovidDeleted} element={<EmovidDeleted />} />
                        <Route path={Paths.recordEmovid} element={<RecordNow />} />
                        <Route path={Paths.editEmovidVideo} element={<RecordNow />} />
                        <Route path={Paths.viewEmovid} element={<ViewEmovid />} />
                        <Route path={Paths.preview} element={<CreateEmovid />} />
                        <Route path={Paths.previewAndEdit} element={<ViewEmovid />} />
                        <Route path={Paths.sent} element={<EmovidSent />} />
                        <Route path={Paths.respond} element={<ViewEmovid />} />
                        <Route path={Paths.recordResponse} element={<RecordResponse />} />
                        <Route path={Paths.editResponseVideo} element={<RecordResponse />} />
                        <Route path={Paths.previewResponse} element={<ResponsePreview />} />
                        <Route path={Paths.responseSent} element={<ResponseSent />} />
                        <Route path={Paths.recordFeedback} element={<RecordResponse />} />
                        <Route path={Paths.editFeedbackVideo} element={<RecordResponse />} />
                        <Route path={Paths.getSupport} element={<GetSupport />} />
                        <Route path={Paths.recordSupport} element={<RecordResponse />} />
                        <Route path={Paths.editSupportVideo} element={<RecordResponse />} />
                        <Route path={Paths.supportRequestSent} element={<SupportRequestSent />} />
                        <Route path={Paths.contactSales} element={<ContactSales />} />
                        <Route path={Paths.contactSalesRequestSent} element={<ContactSalesSent />} />
                        <Route path={Paths.recordContactSales} element={<RecordResponse />} />
                        <Route path={Paths.editContactSalesVideo} element={<RecordResponse />} />
                        <Route path={Paths.useCases} element={<UseCases />} />
                        <Route path={Paths.viewVideo} element={<ViewVideo />} />
                        <Route path={Paths.processing} element={<Progress />} />
                        <Route path={Paths.resetPassword} element={<ResetPassword />} />
                        <Route path={Paths.updatePassword} element={<UpdatePassword />} />
                        <Route path={Paths.terms} element={<Terms />} />
                        <Route path={Paths.privacyPolicy} element={<PrivacyPolicy />} />
                        <Route path={Paths.aboutUs} element={<AboutUs />} />
                        <Route path={Paths.faqPage} element={<FAQPage />} />
                        <Route path={Paths.extension} element={<Extension />} />
                        <Route path={Paths.landing} element={<Landing />} />
                        <Route path={Paths.howToWhitelist} element={<HowToWhitelist />} />
                        <Route path="*" element={<Navigate to={Paths.landing} />} />
                    </Route>
                </Routes>
            </WrappedAuthProvider>
        </BrowserRouter>
    </Suspense>
);

export default AppRoutes;
