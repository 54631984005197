import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Paths, replaceEventIdInPath } from "./AppRoutes";
import { TRY_NOW_EMOVID_ID } from "./Constants";

interface TryNowButtonProps {
    className?: string;
    buttonText?: string;
}

const TryNowButton: FC<TryNowButtonProps> = (props: TryNowButtonProps) => {
    const navigate = useNavigate();

    const callToActionClicked = () => {
        if (TRY_NOW_EMOVID_ID) navigate(replaceEventIdInPath(Paths.viewEmovid, TRY_NOW_EMOVID_ID));
        else console.error("Missing variable REACT_APP_TRY_NOW_EMOVID_ID");
    };

    return (
        <button className={props.className || "btn btn-primary"} onClick={callToActionClicked}>
            {props.buttonText || "Try Now"}
        </button>
    );
};

export default TryNowButton;
